export function getHeart() {
  if (typeof window === "undefined") {
    return 0
  }

  const url = window.location.href
  const key = `heart:${url}`
  const val = parseInt(localStorage.getItem(key))
  if (!val) {
    const ranVal = getRandomInt(10, 100)
    localStorage.setItem(key, ranVal.toString())
    return ranVal
  }
  return val
}

export function increaseHeart(num) {
  if (typeof window === "undefined") {
    return
  }

  const url = window.location.href
  const key = `heart:${url}`
  const val = parseInt(localStorage.getItem(key))
  const increased = val + num
  localStorage.setItem(key, increased.toString())
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
