import React, { useReducer } from "react";
import { graphql, Link, navigate } from "gatsby";

import HeaderSEO from "../components/seo";
import NewsletterPopup from "../components/NewsletterPopup";

import { Comments, FacebookProvider } from "react-facebook";

import TimeAgo from "react-timeago";
import enStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

import { isRandomSite } from "../utils/randomUtils";
import { getHeart, increaseHeart } from "../utils/heartUtils";
import "./pagination.css";

const formatter = buildFormatter(enStrings);

require("prismjs/themes/prism-solarizedlight.css");

const pageState = {
  timeout: null
}

function reducer(state, action) {
  switch (action.type) {
    case "increase":
      return {
        count: state.count + state.pending,
        pending: 0
      };
    case "pending":
      increaseHeart(1)
      return {
        count: state.count,
        pending: state.pending + 1
      };
    default:
      return state;
  }
}

function BlogPostTemplate(props) {
  const [state, dispatch] = useReducer(reducer, {
    count: getHeart(),
    pending: 0,
  }, undefined);

  const increase = () => {
    if (pageState.timeout) {
      clearTimeout(pageState.timeout)
    }

    pageState.timeout = setTimeout(() => {
      dispatch({ type: "increase" })
      pageState.timeout = null
    }, 1000)

    dispatch({ type: "pending" })
  }

  const post = props.data.markdownRemark;
  const pathNamePost = (props.location.pathname || "/").replace(/^\/en/, "");
  const postFix = pathNamePost.slice(6);
  const pathName = "https://tuhuynh.com" + postFix;
  const { count, pending } = state;
  
  // Get language info from pageContext
  const { language, isTranslated, translationSlug } = props.pageContext;

  function goBack(e) {
    e.preventDefault();
    const referrer = props.location?.state?.referrer || "/";
    void navigate(referrer);
  }

  function switchLanguage(e) {
    e.preventDefault();
    void navigate(translationSlug, {
      state: { referrer: props.location?.state?.referrer || "/" }
    });
  }

  return (
    <React.Fragment>
      <HeaderSEO 
        title={post.frontmatter.title} 
        description={post.frontmatter.description || post.excerpt} 
        image={post.frontmatter.image.childImageSharp.fluid.src}
        pageType="article"
        datePublished={post.frontmatter.date}
        dateModified={post.frontmatter.lastModified || post.frontmatter.date}
        keywords={post.frontmatter.tag || []}
        lang={language}
        translationUrl={translationSlug}
      />
      <article className="article-page">
        <div className="back-button" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <a onClick={goBack}>← Back to {isRandomSite() ? 'Random Thoughts' : 'Home'}</a>
          {isTranslated && (
            <a onClick={switchLanguage} style={{ cursor: 'pointer' }}>
              <span role="img" aria-label={language === 'en' ? 'vietnamese flag' : 'us flag'} style={{ fontSize: '0.9rem' }}>
                {language === 'en' ? '🇻🇳' : '🇺🇸'}
              </span>
              →
            </a>
          )}
        </div>
        <div className="page-content">
          <div className="page-cover-image" hidden={post.frontmatter.title === "Resume"}>
            <figure>
              <img className="page-image" src={post.frontmatter.image.childImageSharp.fluid.src} alt="Blog" />
            </figure>
          </div>
          <div className="wrap-content">
            <header className="header-page">
              <h1 className="page-title">{post.frontmatter.title}</h1>
              
              {/* Add language switch section */}
              {isTranslated && (
                <div className="language-switch" style={{ 
                  marginTop: '12px', 
                  marginBottom: '16px',
                  padding: '8px 16px',
                  border: '1px solid #e0e0e0',
                  borderRadius: '6px',
                  display: 'inline-block',
                  transition: 'all 0.2s ease',
                  backgroundColor: '#ffffff',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#f5f5f5',
                    transform: 'translateY(-1px)',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }
                }}>
                  <a onClick={switchLanguage} style={{
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    letterSpacing: '0.2px',
                    fontWeight: '500',
                    color: '#555',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    cursor: 'pointer'
                  }}>
                    <span role="img" aria-label={language === 'en' ? 'vietnamese flag' : 'us flag'} style={{ fontSize: '0.9rem' }}>
                      {language === 'en' ? '🇻🇳' : '🇺🇸'}
                    </span>
                    {language === 'en' ? 'Đọc phiên bản tiếng Việt' : 'Read the English version'}
                  </a>
                </div>
              )}
              
              <div className="page-date" hidden={post.frontmatter.title === "Resume"}>
                  <span>
                    {post.frontmatter.author} wrote <TimeAgo date={post.frontmatter.date} formatter={formatter}/>
                  </span>
              </div>
              <div style={{ marginTop: "1rem" }} hidden={post.frontmatter.title === "Resume"}>
                <div>
                  {!isRandomSite() && post.frontmatter.tag.map((tag) => (
                    <Link to={`/tags/${tag}`} key={tag.toString()} style={{ color: "white" }} className="tagging__item">
                      #{tag}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="clap-container">
                <button id="clap-button" onClick={increase}>
                  <div id="clap-icon">
                    <img src="/heart.png" alt="Clap"/>
                  </div>
                  <div id="clap-count">{pending > 0 ? `+${pending}` : count}{pending > 10 ? "!" : ""}</div>
                </button>
              </div>
            </header>
            <div className="post-body" dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>
        {post.frontmatter.title !== "Resume" && (
          <>
            <div className="inline-newsletter" style={{
              maxWidth: '700px',
              margin: '40px auto',
              padding: '30px 20px',
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              border: '1px solid #e0e0e0'
            }}>
              <div className="newsletter-header" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px'
              }}>
                <img 
                  src="/tu-huynh.jpg" 
                  alt={post.frontmatter.author}
                  className="newsletter-avatar"
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    marginRight: '15px',
                    border: '1px solid #f0f0f0'
                  }}
                />
                <div className="newsletter-text" style={{ 
                  textAlign: 'left'
                }}>
                  <h3 style={{ 
                    margin: '0 0 5px',
                    fontSize: '20px',
                    fontWeight: '600',
                    color: '#333'
                  }}>
                    Subscribe to my newsletter
                  </h3>
                  <p style={{
                    margin: '0',
                    fontSize: '16px',
                    color: '#666'
                  }}>
                    Get notified when I publish new posts on my blog
                  </p>
                </div>
              </div>
              <form 
                onSubmit={async (e) => {
                  e.preventDefault();
                  const email = e.target.email.value;
                  const button = e.target.querySelector('button');
                  const originalText = button.textContent;
                  
                  try {
                    button.disabled = true;
                    button.textContent = 'Subscribing...';
                    
                    const response = await fetch('/.netlify/functions/subscribe', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ email }),
                    });

                    if (!response.ok) {
                      throw new Error('Subscription failed');
                    }

                    button.textContent = 'Subscribed!';
                    e.target.reset();
                    localStorage.setItem('isSubscribed', 'true');
                    
                    setTimeout(() => {
                      button.textContent = originalText;
                      button.disabled = false;
                    }, 3000);
                  } catch (error) {
                    console.error('Subscription error:', error);
                    button.textContent = 'Error! Try again';
                    setTimeout(() => {
                      button.textContent = originalText;
                      button.disabled = false;
                    }, 3000);
                  }
                }}
                className="newsletter-form"
                style={{
                  display: 'flex',
                  gap: '10px',
                  maxWidth: '500px',
                  margin: '0 auto'
                }}
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email..."
                  required
                  className="newsletter-input"
                  style={{
                    flex: 1,
                    padding: '12px 15px',
                    fontSize: '16px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    transition: 'border-color 0.2s'
                  }}
                />
                <button
                  type="submit"
                  className="newsletter-button"
                  style={{
                    backgroundColor: '#e05525',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '12px 25px',
                    fontSize: '16px',
                    fontWeight: '600',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Subscribe
                </button>
              </form>
              <style>
                {`
                  @media (max-width: 480px) {
                    .newsletter-header {
                      flex-direction: column;
                      gap: 15px;
                    }
                    
                    .newsletter-avatar {
                      margin-right: 0 !important;
                    }
                    
                    .newsletter-text {
                      text-align: center !important;
                    }
                    
                    .newsletter-text h3 {
                      font-size: 18px !important;
                    }
                    
                    .newsletter-text p {
                      font-size: 14px !important;
                      padding: 0 10px;
                    }
                    
                    .newsletter-form {
                      flex-direction: column !important;
                    }
                    
                    .newsletter-input {
                      width: 100% !important;
                      font-size: 14px !important;
                    }
                    
                    .newsletter-button {
                      width: 100% !important;
                      font-size: 14px !important;
                    }
                  }
                `}
              </style>
            </div>
            <div className="facebook-comments-container" style={{ width: '100%', margin: '0 auto', overflow: 'hidden' }}>
              <FacebookProvider appId="453732918610803">
                <Comments href={pathName} width="100%" />
              </FacebookProvider>
            </div>
          </>
        )}
      </article>
      <NewsletterPopup author={post.frontmatter.author} title={post.frontmatter.title} />
    </React.Fragment>
  );
}

export default React.memo(BlogPostTemplate);

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author
        tag
      }
    }
  }
`;
