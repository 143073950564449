import React, { useState, useEffect } from "react";
import "./NewsletterPopup.css";

const NewsletterPopup = ({ author, title }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState("");
  const [hasScrolledThreshold, setHasScrolledThreshold] = useState(false);

  useEffect(() => {
    // Check if user already subscribed
    const subscribed = localStorage.getItem("isSubscribed") === "true";
    setIsSubscribed(subscribed);
    
    // Check if user has dismissed the popup recently (within 24 hours)
    const dismissedTime = localStorage.getItem("popupDismissedTime");
    if (window.location.hostname !== "localhost" && dismissedTime && Date.now() - parseInt(dismissedTime) < 24 * 60 * 60 * 1000) {
      // Don't show the popup if it was dismissed less than 24 hours ago
      return;
    }

    const handleScroll = () => {
      // Get scroll position and page height
      const scrollPosition = window.scrollY;
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      
      // Only show popup on pages where there's enough content to scroll
      if (totalHeight < 300) return;
      
      // Show popup when user scrolls past 30% of the page
      const scrollThreshold = 0.3; // 30%
      
      // Calculate threshold in pixels, with a maximum of 1200px
      const pixelThreshold = Math.min(totalHeight * scrollThreshold, 1200);
      
      if (scrollPosition > pixelThreshold && !isSubscribed && !showSuccess) {
        setHasScrolledThreshold(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isSubscribed, showSuccess]);
  
  // Add a delay after reaching the scroll threshold
  useEffect(() => {
    if (hasScrolledThreshold) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1500); // 1.5 second delay after scrolling to threshold
      
      return () => clearTimeout(timer);
    }
  }, [hasScrolledThreshold]);

  const handleClose = () => {
    setIsVisible(false);
    // Save the current time when the user dismisses the popup
    localStorage.setItem("popupDismissedTime", Date.now().toString());
  };

  const handleSubscriptionSuccess = () => {
    setIsSubmitting(false);
    setIsSubscribed(true);
    setShowSuccess(true);
    setIsVisible(false);
    
    // Store in localStorage to remember this user has subscribed
    localStorage.setItem("isSubscribed", "true");
    
    // Show success message and then hide it after 5 seconds
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      setIsSubmitting(false);
      return;
    }
    
    try {
      const response = await fetch('/.netlify/functions/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Subscription failed');
      }

      handleSubscriptionSuccess();
    } catch (error) {
      setError(error.message || 'Failed to subscribe. Please try again later.');
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isVisible && (
        <div className="newsletter-popup-overlay" onClick={handleClose}>
          <div className="newsletter-popup" onClick={(e) => e.stopPropagation()}>
            <button className="newsletter-popup-close" onClick={handleClose}>
              ×
            </button>
            
            <div className="newsletter-popup-header">
              <div className="newsletter-popup-avatar">
                <img 
                  src="/tu-huynh.jpg" 
                  alt={author || "Blog Author"}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/default-avatar.png";
                  }}
                />
              </div>
              
              <div className="newsletter-popup-info">
                <h2>Discover more from Tú's notes</h2>
                <p>How software works under the hood</p>
                <p className="newsletter-popup-subscribers">Over 100 subscribers</p>
              </div>
            </div>
            
            <form onSubmit={handleSubmit} className="newsletter-popup-form">
              <input
                type="email"
                placeholder="Enter your email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={`newsletter-popup-input ${error ? "newsletter-popup-input-error" : ""}`}
              />
              {error && <div className="newsletter-popup-error">{error}</div>}
              <button 
                type="submit" 
                className="newsletter-popup-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Subscribing..." : "Subscribe"}
              </button>
            </form>
            
            <div className="newsletter-popup-footer">
              <p>
                By subscribing, I agree to <a href="/terms-of-use">Terms of Use</a>, and acknowledge its <a href="/privacy-policy">Privacy Policy</a>.
              </p>
              <div className="newsletter-popup-sign-in">
                Already have an account? <a href="#">Sign in</a>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {showSuccess && (
        <div className="newsletter-success">
          <div className="newsletter-success-content">
            <span className="newsletter-success-icon">✓</span>
            <span>Thanks for subscribing!</span>
            <button onClick={() => setShowSuccess(false)} className="newsletter-success-close">×</button>
          </div>
        </div>
      )}
    </>
  );
};

export default NewsletterPopup; 