/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

function SEO({ description, lang, meta, keywords, title, image, pageType = "website", datePublished, dateModified, translationUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaImage = site.siteMetadata.siteUrl + (image || "/cover.jpg");
  const canonicalUrl = site.siteMetadata.siteUrl + (typeof window !== 'undefined' ? window.location.pathname : '');

  // Structured data for Google
  const structuredData = {
    "@context": "https://schema.org",
    "@type": pageType === "article" ? "BlogPosting" : "WebSite",
    "url": canonicalUrl,
    "name": title,
    "description": metaDescription,
    "author": {
      "@type": "Person",
      "name": site.siteMetadata.author
    },
    "inLanguage": lang || "vi",
    ...(pageType === "article" && {
      "datePublished": datePublished,
      "dateModified": dateModified || datePublished,
      "image": metaImage
    })
  };

  const linkTags = [
    { rel: "canonical", href: canonicalUrl }
  ];

  // Add alternate language link if translation exists
  if (translationUrl) {
    linkTags.push({
      rel: "alternate",
      hrefLang: lang === "en" ? "vi" : "en",
      href: site.siteMetadata.siteUrl + translationUrl
    });
    // Add x-default hreflang for Vietnamese version
    if (lang === "vi") {
      linkTags.push({
        rel: "alternate",
        hrefLang: "x-default",
        href: canonicalUrl
      });
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || "vi",
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={linkTags}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: pageType,
        },
        {
          property: `og:url`,
          content: canonicalUrl,
        },
        {
          property: `og:locale`,
          content: lang === "en" ? "en_US" : "vi_VN",
        },
        ...(translationUrl ? [{
          property: `og:locale:alternate`,
          content: lang === "en" ? "vi_VN" : "en_US",
        }] : []),
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        // Add article meta tags if it's a blog post
        ...(pageType === "article" ? [
          {
            property: `article:published_time`,
            content: datePublished,
          },
          {
            property: `article:modified_time`,
            content: dateModified || datePublished,
          },
          {
            property: `article:author`,
            content: site.siteMetadata.author,
          }
        ] : []),
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700|JetBrains+Mono:400,700&display=swap&subset=vietnamese" rel="stylesheet"/>
      <script data-ad-client="ca-pub-2493636166437512" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `vi`,
  meta: [],
  keywords: [],
  description: ``,
  pageType: "website",
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pageType: PropTypes.string,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  translationUrl: PropTypes.string,
};

export default React.memo(SEO);
